import { SectionTestimonialsSliceDefaultItem } from "prismicio-types";
import React from "react";

import { ScrollElement } from "@components/ScrollElement";
import { SectionTemplate } from "@views/ClubFormView/components";
import { getSectionVariant } from "@views/ClubFormView/components/SectionTemplate/misc";
import { getDateDiff } from "@views/ClubFormView/utils";

import { SectionTestimonialsProps } from "../..";

import { ReactComponent as Star } from "assets/icons/club-rating.svg";

import * as S from "./styles";

export type ChoiceItemProps = {
  item: SectionTestimonialsSliceDefaultItem;
};

const outputRating = (rating: number) => {
  const stars = [];
  const maxRating = 5;

  const effectiveRating = Math.min(rating, maxRating);

  for (let i = 0; i < effectiveRating; i++) {
    stars.push(<Star key={i} />);
  }

  return stars;
};

const TestimonialComp = ({ item }: ChoiceItemProps) => {
  const { header, text, date, name, rating } = item;
  const createdOn = date ? new Date(date) : new Date();
  const reviewDate = getDateDiff(createdOn);

  return (
    <S.ItemWrapper>
      {rating && rating !== 0 ? (
        <S.Rating>{outputRating(rating)}</S.Rating>
      ) : null}
      <S.ReviewWrapper>
        {header && <S.HeaderTag>{header}</S.HeaderTag>}
        {text && <S.TextTag>{text}</S.TextTag>}
      </S.ReviewWrapper>

      <S.BottomWrapper>
        {name && <S.NameTag>{name}</S.NameTag>}
        {date && <S.DateTag>{reviewDate}</S.DateTag>}
      </S.BottomWrapper>
    </S.ItemWrapper>
  );
};

export const SectionTestimonials = ({
  index,
  slice,
}: SectionTestimonialsProps): JSX.Element | null => {
  const { items, primary } = slice;
  const { header, text, is_active, slice_id } = primary;

  const displaySection = is_active ?? true;

  if (!displaySection) {
    return null;
  }

  return (
    <SectionTemplate
      header={header}
      text={text}
      variant={getSectionVariant(index)}
    >
      <ScrollElement id={(slice_id as string) || "section-testimonial"} />
      <S.SectionTestimonialWrapper>
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <TestimonialComp
              item={item}
              key={`${item?.date}_${item?.name}_${index}`}
            />
          ))}
      </S.SectionTestimonialWrapper>
    </SectionTemplate>
  );
};

export default SectionTestimonials;
