import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React from "react";

import { SliceVariants } from "@components/SliceVariants";

import { components } from "./variants";

/**
 * Props for `SectionTestimonials`.
 */
export type SectionTestimonialsProps =
  SliceComponentProps<Content.SectionTestimonialsSlice>;

/**
 * Component for "SectionTestimonials" Slices.
 */
const SectionTestimonials = ({
  slice,
  ...config
}: SectionTestimonialsProps): JSX.Element => (
  <SliceVariants {...config} components={components} slice={slice} />
);

export default SectionTestimonials;
