import { truncateText } from "@components/Navigation/mixins";
import { media, styled } from "@styles";

export const SectionTestimonialWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 2rem;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;

  h3 {
    line-height: 1.5em;
  }

  ${media.md.up} {
    flex-flow: row wrap;
    max-width: 800px;
  }
  ${media.xl.up} {
    max-width: 1000px;
  }
`;

export const ItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 24px;
  padding: 24px;
  color: ${({ theme }) => theme.colors.white};
  border: 4px ${({ theme }) => theme.colors.black} solid;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
  margin-top: auto;
`;

export const ReviewWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 14px;
`;

export const Rating = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 7px;

  svg {
    width: 14px;
    height: 14px;
    path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const HeaderTag = styled.span`
  font-weight: 600;
`;

export const TextTag = styled.p`
  color: ${({ theme }) => theme.colors.light};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  ${truncateText({ lines: 3 })}

  line-height: 1.2em;
`;

export const NameTag = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  font-weight: 600;
`;

export const DateTag = styled.span`
  font-size: ${({ theme }) => theme.typography.sizes.smallest};
  color: ${({ theme }) => theme.colors.dark};
`;
