import { RichTextField } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import React, { useMemo } from "react";

import { Container } from "@components/Container";
import { useRouter } from "@hooks/useRouter";
import { Theme } from "@styles/theme";

import * as S from "./styles";

export type SectionVariantType = keyof Theme["sections"];

export type SectionTemplateProps = {
  children?: React.ReactNode;
  header: RichTextField;
  text: RichTextField;
  variant: SectionVariantType;
};

export const SectionTemplate = ({
  header,
  text,
  children,
  variant = "light",
}: SectionTemplateProps): JSX.Element => {
  const { asPath } = useRouter();

  const isClubPage = useMemo(() => asPath.includes("club"), [asPath]);

  return (
    <S.SectionWrapper variant={variant}>
      <Container>
        <S.SectionContainer>
          <S.SectionWrapperText isClubPage={isClubPage}>
            {header && <PrismicRichText field={header} />}
            {text && <PrismicRichText field={text} />}
          </S.SectionWrapperText>
          <S.SectionWrapperContent>{children}</S.SectionWrapperContent>
        </S.SectionContainer>
      </Container>
    </S.SectionWrapper>
  );
};
