import { css } from "styled-components";

import { media, styled } from "@styles";

import { SectionVariantType } from "./SectionTemplate";

export const SectionWrapper = styled.section<{
  variant: SectionVariantType;
}>`
  display: flex;
  flex-flow: column;
  min-height: 60vh;
  padding-block: 3rem;
  background-color: ${({ theme, variant }) =>
    `${
      theme.sections[variant]?.backgroundColor ||
      theme.sections.light.backgroundColor
    }`};

  ${media.md.up} {
    padding-block: 60px;
  }
`;

export const SectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 4rem;
`;

export const SectionWrapperText = styled.div<{ isClubPage?: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: center;
  max-width: 600px;

  ${({ isClubPage }) =>
    isClubPage &&
    css`
      h2 {
        font-size: 40px;
      }
    `}

  ${media.md.up} {
    gap: 22px;
  }
`;

export const SectionWrapperContent = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
`;
